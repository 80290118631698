<template>
    <div class="quickCheckSetting-slider">
        <div class="tree-con">
            <el-tree
                :data="treeData"
                :props="defaultProps"
                :highlight-current="true"
                :expand-on-click-node="false"
                node-key="id"
                :default-expanded-keys="defaultKey"
                :current-node-key="currentNodeKey"
                class="quickCheckSetting-slider-tree"
                :indent="0"
                ref="tree"
                @node-click="loadTable"
            >
                <span class="slot-t-node" slot-scope="{ node, data }">
                    <div class="parent-node" v-if="data.childs.length>0">
                        <i v-if="node.expanded" class="iconfont iconshouqi" @click="expandtree(node)"></i>
                        <i v-if="!node.expanded" class="iconfont iconzhankai" @click="expandtree(node)"></i>
                        <span v-if="node.expanded" class="iconfont iconwenjianjia-zhankai" @click="expandtree(node)"></span>
                        <span v-if="!node.expanded" class="iconfont iconwenjianjia-shouqi" @click="expandtree(node)"></span>
                        <span>{{ data.name }}</span>
                    </div>
                    <div class="leaf-node" v-else @click="loadTable(data)">
                        <span class="iconfont iconlingxing"></span>
                        <span>{{ data.name }}</span>
                    </div>
                </span>
            </el-tree>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {},
    data() {
        return {
            treeData: [],
            defaultProps: {
                children: 'childs',
                label: 'name',
            },
            defaultKey: [], // 默认展开节点id集合
            currentNodeKey: '', // 当前节点key
            menuVisible: false,
            currentNode: {}, // 当前节点数据
        };
    },
    watch: {},
    computed: {},
    methods: {
        // 展开节点
        expandtree(node) {
            node.expanded = !node.expanded;
            if (node.expanded) {
                this.defaultKey = [];
                this.defaultKey.push(node.data.id);
            } else {
                this.defaultKey = [];
            }
        },
        // 拉取业务分组树
        getTree() {
            this.$axios
                .get('/interfaceApi/procurement/quality/config/gettree')
                .then(res => {
                    if (res) {
                        const resParse = this.setName(res);
                        this.treeData = resParse;
                        if (resParse[0]) {
                            this.$emit('load-table', resParse[0]);
                        }
                    }

                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        setName(datas) { // 遍历树  获取id数组
            for (const i in datas) {
                datas[i].id = datas[i].name + '_' + datas[i].sort_num;
                if (datas[i].childs) {
                    datas[i].childs = this.setName(datas[i].childs);
                }
            }
            return datas;
        },
        // 加载表格
        loadTable( data) {
            this.$emit('load-table', data);
        },
    },
    created() {
        this.getTree();
    },
    mounted() {},
};
</script>
<style lang="stylus" scoped>
.quickCheckSetting-slider
    width 2.2rem
    height 100%
    background #fff
    overflow hidden
    float left
    .new-module
        height .54rem
        background #F8F8F8
        border-bottom 1px solid #D7D7D7
        line-height .54rem
        color #333
        cursor pointer
        .iconxianshi_tianjia
            margin 0 .09rem 0 .2rem
        &:hover
            color #5588F1
    .tree-con
        height 100%
        overflow-y auto
        overflow-x auto
        .slot-t-node
            line-height .38rem
            height .38rem
            .parent-node
                .iconzhankai
                    color #5588F1
                .iconshouqi
                    color #F19F26
                .iconwenjianjia-shouqi
                    color #5588F1
                .iconwenjianjia-zhankai
                    color #F19F26
                .iconfont
                    margin-right .1rem
            .leaf-node
                color #666666
                .iconlingxing
                    color #979EA7
                    margin 0 .1rem 0 .03rem
                    font-size .14rem


.tree-con
    overflow hidden
    .quickCheckSetting-slider-tree /deep/ .el-tree-node
        position relative
        padding-left 0
    .quickCheckSetting-slider-tree /deep/ .el-tree-node__children
        padding-left 16px
    // .quickCheckSetting-slider-tree /deep/ .el-tree-node :last-child:before
    //     height 12px
    .quickCheckSetting-slider-tree /deep/ .el-tree > .el-tree-node:before
        border-left none
    .quickCheckSetting-slider-tree-container /deep/ .el-tree > .el-tree-node:after
        border-top none
    .quickCheckSetting-slider-tree /deep/ .el-tree-node:before
        content ''
        left .26rem
        position absolute
        right auto
        border-width 1px
        border-left 1px dashed #4386c6
        bottom 0px
        height 100%
        top .25rem
        width 1px
        z-index 5
    .quickCheckSetting-slider-tree /deep/ .el-tree-node:after
        content ''
        left .38rem
        position absolute
        right auto
        border-width 1px
        border-top 1px dashed #4386c6
        height .2rem
        top .17rem
        width .1rem
    // .quickCheckSetting-slider-tree /deep/ .el-tree-node:last-child:before
    //     border-width 0 !important
    //     border-left none !important
    //     height 0 !important
    //     width 0 !important
    // .quickCheckSetting-slider-tree /deep/ .el-tree-node:last-child:after
    //     border-width 0 !important
    //     border-top none !important
    //     top .17rem !important
    //     width 0 !important
    // .quickCheckSetting-slider-tree /deep/ .el-tree-node:nth-child(1):before
    //     border-width 1px !important
    //     border-left 1px dashed #4386c6 !important
    //     height 100% !important
    //     width 1px !important
    // .quickCheckSetting-slider-tree /deep/ .el-tree-node:nth-child(1):after
    //     border-width 1px !important
    //     border-top 1px dashed #4386c6 !important
    //     top .17rem !important
    //     width .1rem !important
// >>> .quickCheckSetting-slider .tree-con .slot-t-node .leaf-node .iconlingxing[data-v-80a5ce56]
//     display none
// >>> .quickCheckSetting-slider .tree-con .slot-t-node .leaf-node[data-v-80a5ce56]
//     margin-left .48rem
</style>
<style lang="stylus">
.quickCheckSetting-slider .el-icon-caret-right:before{
    display: none!important;
}
.quickCheckSetting-slider .el-tree-node__content{
    height: .36rem;
}
.quickCheckSetting-slider .tree-con .is-current>.el-tree-node__content .slot-t-node .leaf-node{
    color: #FE7903;
}
.quickCheckSetting-slider .tree-con .is-current>.el-tree-node__content .slot-t-node .leaf-node .iconlingxing{
    color: #FE7903;
}
.quickCheckSetting-slider .el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content{
    background-color:#E2EAF0;
}
</style>
