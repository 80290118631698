<template>
    <div class="quickCheckSetting-table">
        <div class="btn-con">
            <span class="btn" @click="choseGB">选择指标项</span>
        </div>
        <div class="table-con table-bg table-header-bg">
            <el-table
                :data="tableData"
                height="100%"
                border
                style="width: 100%"
                stripe
            >
                <el-table-column
                    prop="item_name"
                    label="快检项目"
                    show-overflow-tooltip
                    fixed
                    width="180"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.item_name }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="gb_index"
                    label="国标指标"
                    width="150"
                >
                    <template slot-scope="scope">
                        {{ getItemStr(scope.row.gb_index) }}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="standard_index"
                    label="合格接收指标"
                    width="380"
                >
                    <template slot-scope="scope">
                        <template v-if="scope.row.index_type==0">
                            <el-select
                                class="symbol-tag-select"
                                v-model="scope.row.standard_index.symbol_min"
                                placeholder=""
                            >
                                <el-option
                                    v-for="(item, index) in optionsMin"
                                    :key="scope.row.item_code+index"
                                    :value="item.value"
                                >
                                </el-option>
                            </el-select>
                            <el-input class="value-input" v-model="scope.row.standard_index.value_min">
                                <template slot="suffix">
                                    {{ scope.row.unit }}
                                </template>
                            </el-input>
                            <el-select
                                class="join-select"
                                v-model="scope.row.standard_index.join_value"
                            >
                                <el-option label="并且" value="并且"></el-option>
                                <el-option label="或者" value="或者"></el-option>
                            </el-select>
                            <el-select
                                class="symbol-tag-select"
                                v-model="scope.row.standard_index.symbol_max"
                                placeholder=""
                            >
                                <el-option
                                    v-for="(item, index) in optionsMax"
                                    :key="scope.row.item_code+index"
                                    :value="item.value"
                                >
                                </el-option>
                            </el-select>
                            <el-input class="value-input" v-model="scope.row.standard_index.value_max">
                                <template slot="suffix">
                                    {{ scope.row.unit }}
                                </template>
                            </el-input>
                        </template>
                        <template v-else>
                            <el-input class="custom-value-input" placeholder="请输入" v-model="scope.row.standard_index.custom_value">
                                <template slot="suffix">
                                    {{ scope.row.unit }}
                                </template>
                            </el-input>
                        </template>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="not_standard_index"
                    label="不合格指标"
                    width="300"
                >
                    <template slot-scope="scope">
                        <template v-if="scope.row.index_type==0">
                            <el-select
                                class="symbol-tag-select"
                                v-model="scope.row.not_standard_index.symbol_min"
                                placeholder=""
                            >
                                <el-option
                                    v-for="(item, index) in optionsMin"
                                    :key="scope.row.item_code+index"
                                    :value="item.value"
                                >
                                </el-option>
                            </el-select>
                            <el-input
                                class="value-input"
                                v-model="scope.row.not_standard_index.value_min"
                            >
                                <template slot="suffix">
                                    {{ scope.row.unit }}
                                </template>
                            </el-input>
                            <el-select class="join-select" v-model="scope.row.not_standard_index.join_value">
                                <el-option label="并且" value="并且"></el-option>
                                <el-option label="或者" value="或者"></el-option>
                            </el-select>
                            <el-select
                                class="symbol-tag-select"
                                v-model="scope.row.not_standard_index.symbol_max"
                                placeholder=""
                            >
                                <el-option
                                    v-for="(item, index) in optionsMax"
                                    :key="scope.row.item_code+index"
                                    :value="item.value"
                                >
                                </el-option>
                            </el-select>
                            <el-input class="value-input" v-model="scope.row.not_standard_index.value_max">
                                <template slot="suffix">
                                    {{ scope.row.unit }}
                                </template>
                            </el-input>
                        </template>
                        <template v-else>
                            <el-input class="custom-value-input" placeholder="请输入" v-model="scope.row.not_standard_index.custom_value"></el-input>
                        </template>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="remarks"
                    label="是否必检"
                    width="100"
                >
                    <template slot-scope="scope">
                        <el-radio-group v-model="scope.row.is_must_check">
                            <el-radio :label="true">
                                是
                            </el-radio>
                            <el-radio :label="false">
                                否
                            </el-radio>
                        </el-radio-group>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="remarks"
                    label="备注"
                    width="300"
                >
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.remarks" placeholder="请输入备注信息"></el-input>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="is_deduction_weight"
                    label="自动扣吨"
                    width="80"
                >
                    <template slot-scope="scope">
                        <el-switch v-model="scope.row.is_deduction_weight"></el-switch>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="compromise_index"
                    label="让步接收指标"
                    width="330"
                >
                    <template slot-scope="scope">
                        <template v-if="scope.row.index_type==0">
                            <el-select
                                class="symbol-tag-select"
                                v-model="scope.row.compromise_index.symbol_min"
                                placeholder=""
                            >
                                <el-option
                                    v-for="(item, index) in optionsMin"
                                    :key="scope.row.item_code+index"
                                    :value="item.value"
                                >
                                </el-option>
                            </el-select>
                            <el-input class="value-input" v-model="scope.row.compromise_index.value_min">
                                <template slot="suffix">
                                    {{ scope.row.unit }}
                                </template>
                            </el-input>
                            <el-select
                                class="join-select"
                                v-model="scope.row.compromise_index.join_value"
                            >
                                <el-option label="并且" value="并且"></el-option>
                                <el-option label="或者" value="或者"></el-option>
                            </el-select>
                            <el-select
                                class="symbol-tag-select"
                                v-model="scope.row.compromise_index.symbol_max"
                                placeholder=""
                            >
                                <el-option
                                    v-for="(item, index) in optionsMax"
                                    :key="scope.row.item_code+index"
                                    :value="item.value"
                                >
                                </el-option>
                            </el-select>
                            <el-input class="value-input " v-model="scope.row.compromise_index.value_max">
                                <template slot="suffix">
                                    {{ scope.row.unit }}
                                </template>
                            </el-input>
                        </template>
                        <template v-else>
                            <el-input class="custom-value-input" placeholder="请输入" v-model="scope.row.compromise_index.custom_value"></el-input>
                        </template>
                    </template>
                </el-table-column>
                <el-table-column
                    label="扣吨配置"
                    width="350"
                >
                    <template slot-scope="scope">
                        <template v-if="scope.row.index_type==0">
                            <span>每</span>
                            <el-select
                                class="join-select"
                                v-model="scope.row.out_symbol"
                            >
                                <el-option label="低" value="低"></el-option>
                                <el-option label="超" value="超"></el-option>
                            </el-select>
                            <el-input class="value-input" v-model="scope.row.out_value">
                                <template slot="suffix">
                                    {{ scope.row.unit }}
                                </template>
                            </el-input>
                            <span>扣计量的</span>
                            <el-input class="value-input " v-model="scope.row.deduction_weight_value"></el-input>
                            <el-select
                                class="symbol-tag-select"
                                v-model="scope.row.deduction_weight_value_symbol"
                            >
                                <el-option label="%" value="%"></el-option>
                            </el-select>
                        </template>
                        <template v-else>
                            ——
                        </template>
                    </template>
                </el-table-column>
                <el-table-column
                    label="操作"
                    width="50"
                    fixed="right"
                >
                    <template slot-scope="scope">
                        <span
                            class="del_span"
                            @click="handleDelete(scope.row)"
                        >
                            删除
                        </span>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        rbData: {
            type: [Array],
        },
        currentData: {
            type: [Object],
        },
    },
    data() {
        return {
            optionsMin: [
                {
                    value: '=',
                    label: '=',
                },
                {
                    value: '!=',
                    label: '!=',
                },
                {
                    value: '>',
                    label: '>',
                },
                {
                    value: '≥',
                    label: '≥',
                },
                {
                    value: '<',
                    label: '<',
                },
                {
                    value: '≤',
                    label: '≤',
                },
            ],
            optionsMax: [
                {
                    value: '=',
                    label: '=',
                },
                {
                    value: '!=',
                    label: '!=',
                },
                {
                    value: '>',
                    label: '>',
                },
                {
                    value: '≥',
                    label: '≥',
                },
                {
                    value: '<',
                    label: '<',
                },
                {
                    value: '≤',
                    label: '≤',
                },
            ],
            tableData: [],
        };
    },
    watch: {
        rbData: {
            handler(newV) {
                this.tableData = JSON.parse(JSON.stringify(newV));
            },
            deep: true,
        },
    },
    computed: {},
    methods: {
        handleDelete(data) {
            this.tableData.map((item, index) => {
                if (data.item_code === item.item_code) {
                    this.tableData.splice(index, 1);
                }
            });
            if (data.id) {
                this.deleteRb(data.id);
            }
        },
        // 删除合格指标及让步指标
        deleteRb(id) {
            this.$axios
                .delete('/interfaceApi/procurement/quality/config/detail/delete?ids=' + id)
                .then(res => {
                    if (res) {
                        this.$message.success('删除成功');
                    }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 弹出国标列表
        choseGB() {
            this.$toast({
                title: true,
                text: '请选择国标',
                type: 'eject',
                t_url: 'technicalQualityManage/material/quickCheckSetting/GBtableListPop',
                viewPosition: 'view',
                extr: {
                    closeBtn: {},
                    currentData: this.currentData,
                    selectedData: this.tableData,
                    callBack: this.callBackGB,
                    tableData: this.tableData,
                },
            });
        },
        // 选择完国标回调
        callBackGB(data) {
            window.hideToast();
            data.map(item => {
                const gbObjSet = this.$parent.getItemSet(item.gb_index);
                const row = {
                    type_code: this.currentData.type_code,
                    type_name: this.currentData.type_name,
                    child_type_code: this.currentData.child_type_code,
                    child_type_name: this.currentData.child_type_name,
                    spec_code: this.currentData.spec_code,
                    spec_name: this.currentData.spec_name,
                    item_code: item.item_code,
                    item_name: item.item_name,
                    gb_index: item.gb_index,
                    remarks: item.remarks,
                    type_dic_code: this.currentData.type_dic_code,
                    child_type_dic_code: this.currentData.child_type_dic_code,
                    spec_dic_code: this.currentData.spec_dic_code,
                    index_type: item.index_type,
                    standard_index: Object.assign({}, gbObjSet),
                    compromise_index: Object.assign({}, gbObjSet),
                    not_standard_index: Object.assign({}, gbObjSet),
                    is_must_check: true,
                };
                this.tableData.push(row);
            });

        },
        findInTableData(item) {
            let findIndex = -1;
            this.tableData.map((v, index) => {
                if (v.item_code === item.item_code) {
                    findIndex = index;
                }
            });
            return findIndex;
        },
        // 合格指标下拉事件
        changeStandard(event, row, index) {
            this.$set(this.tableData, index, row);
        },
        // 让步指标下拉事件
        changeCompromise(event, row, index) {
            this.$set(this.tableData, index, row);
        },
        // 获取指标字符串
        getItemStr(str) {
            return (str || '').replace(/\|/g, '');
        },
    },
    created() {
        this.tableData = JSON.parse(JSON.stringify(this.rbData));
    },
    mounted() {},
};
</script>
<style lang="stylus">
.table-header-bg .el-table th
    background #ffffff !important
    padding .02rem
.table-bg .el-table th >.cell
    background #F8F8F8 !important
.quickCheckSetting-table
    float left
    width calc(100% - 2.4rem)
    margin-left .2rem
    height 100%
    background #fff
    padding .1rem
    .btn-con
        .btn
            padding .05rem .1rem
            text-align center
            background #1577D2
            border-radius .04rem
            display inline-block
            color #ffffff
            cursor pointer
            margin-bottom .1rem
    .table-con
        height calc(100% - .4rem)
        .symbol-tag-select
        .del_span
            color #D5000F
            cursor pointer
        .iconshanchu
            color #D5000F
            cursor pointer
        .custom-input
            width .6rem
            height .36rem
            line-height .36rem
            margin-right .05rem
            text-align left!important
        .cell
            display flex
            justify-content center
            align-items center
            *
                flex-shrink 0
        .el-select
            width: 0.6rem;
            display: inline-block;
            margin-right: .05rem;
        .value-input
            width: 0.9rem;
            display: inline-block;
            margin-right: .05rem;
        input
            padding 0 0.08rem
            height 0.36rem;

        .join-select
            width 1rem
            input
                border 1px solid #E8DBC8
                background #FEFBF3
                color #786635
        .custom-value-input
            margin-left -0.03rem
            width 2.8rem;
        .el-radio-group
            .el-radio
                margin-right 0.06rem
                .el-radio__label
                    padding-left 0.03rem
                    display inline-block
        .el-input__suffix
            display flex
            align-items center
            color #666
</style>
